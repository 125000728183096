<template>
  <div class="position-relative w-100">
    <form class="form-box">
      <b-row class="no-gutters">
        <b-col>
          <h1 class="font-weight-bold header-main text-uppercase mb-3">
            {{ id > 0 ? "แก้ไข" : "สร้าง" }}โปรโมชั่น
          </h1>
        </b-col>
      </b-row>
      <b-tabs class="product-tabs">
        <b-tab :title="'รายละเอียดโปรโมชั่น'" active>
          <PromotionDetailsSection />
        </b-tab>
      </b-tabs>
    </form>
  </div>
</template>

<script>
import PromotionDetailsSection from "@/views/pages/promotion/components/PromotionDetailsSection";

export default {
  components: {
    PromotionDetailsSection,
  },
  data() {
    return {
      id: this.$route.params.id,
    };
  },
};
</script>

<style scoped></style>
